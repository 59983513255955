import { stringifyUrl } from 'query-string';

export { default as APICaller } from '@ftf-old/util/ApiCaller';

export function buildEndpoint(url, query = {}) {
  const endpoint = stringifyUrl(
    { url, query },
    {
      arrayFormat: 'comma',
      encode: false,
      sort: false,
    },
  );

  return endpoint;
}
