import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isNil, isEmpty, padStart } from 'lodash';

import { secondsToTime } from '@ftf-old/util/helpers';
import { EntityPresenter } from '@ftf/lenderDashboard/presenters/EntityPresenter';

/** Formats currency value into US format
 * @param {string | number} number A number
 * @param {object} [options={}] extra options passed to Intl.NumberFormat
 * @param {string} [fallback='N/A'] fallback string if no value provided
 * @returns {string} Formatted number
 */
export function formatCurrency(number, options = {}, fallback = 'N/A') {
  if (isNil(number) || number.length === 0 || number === fallback)
    return fallback;

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    ...options,
  }).format(number);
}

/** Formats phone number into international format
 * @param {string | number} phone A phone number
 * @returns {string} Formatted number
 */
export function formatPhone(phone) {
  const phoneNumber = parsePhoneNumberFromString(String(phone), 'US');

  if (isNil(phoneNumber)) {
    return 'N/A';
  }

  return phoneNumber.formatInternational();
}

/**
 * Converts a date in iso format into an array
 * @param {string} Date in ISO format
 * @returns {[string, string, string]} An array with the `[year, month, day]` format
 * @example
 * const [year, month, day] = getDateArray('2020-01-01T00:00:00.351Z');
 */
function getDateArray(date) {
  if (typeof date !== 'string') return undefined;

  const dateString = date.length > 10 ? date.split('T')[0] : date;
  const dateArray = dateString.split('-');

  return dateArray;
}

/**
 * Formats date ISO string into human-readable format
 * @param {string} date A date
 * @returns {string} Formatted date
 */
export function formatDate(date) {
  if (isValidDateFormat(date)) return date;

  const dateArray = getDateArray(date);

  if (isNil(dateArray) || isEmpty(dateArray)) {
    return 'N/A';
  }

  const [year, month, day] = dateArray;

  return `${month}/${day}/${year}`;
}

/**
 * Formats month ISO string into human-readable format
 * @param {string} date A date
 * @returns {string} Formatted date
 */
export function formatMonth(date, monthOnly = false) {
  const dateArray = getDateArray(date);

  if (isNil(dateArray) || isEmpty(dateArray)) {
    return 'N/A';
  }

  const [year, month] = dateArray;

  const months = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };

  return monthOnly ? months[month] : `${months[month]} ${year}`;
}

/**
 * Formats a number of seconds as `m:ss`
 * @param {number} seconds A number of seconds
 * @returns {string} Formatted time
 * @example
 * const time = formatSecondsToTime(95);
 * time // 1:35
 */
export function formatSecondsToTime(seconds) {
  const time = secondsToTime(seconds);
  return `${time.minutes}:${padStart(time.seconds, 2, '0')}`;
}

/**
 * Shortens a given formatted amount and adds the corresponding suffix
 * @param {number} inputAmount An amount string or number
 * @returns {string} Shortened amount
 * @example
 * shortenAmount("$21,758,000") // "$21.758M";
 * shortenAmount("$1,750,000") // "$1.75M";
 * shortenAmount("$250,000") // "$250K";
 * shortenAmount("$100") // "$100";
 */
export function shortenAmount(inputAmount) {
  if (isNil(inputAmount)) return '';

  let amount = inputAmount;

  if (typeof amount === 'string') {
    amount = amount.replace(/\D/g, '');
    amount = Number(amount);
  }

  if (amount < 1000) {
    return inputAmount;
  }

  if (amount >= 10000 && amount < 1000000) {
    return `$${amount / 1000}K`;
  }

  if (amount >= 1000000 && amount < 1000000000) {
    return `$${amount / 1000000}M`;
  }

  return inputAmount.includes('$') ? inputAmount : `$${inputAmount}`;
}

function decoratePercentage(value, withSigns = true) {
  const formattedValue = parseFloat(value.toFixed(2)).toString();

  let text;

  switch (true) {
    case value < 0:
      text = `${formattedValue}%`;
      break;

    case value === 0:
      text = formattedValue;
      break;

    case value > 0:
      text = withSigns ? `+${formattedValue}%` : `${formattedValue}%`;
      break;

    default:
      break;
  }

  return text;
}

export function formatPercentage(value, withSigns = true, fallback = 'N/A') {
  if (isNil(value) || value.length === 0) return fallback;

  return decoratePercentage(parseFloat(value), withSigns);
}

export function formatNumberToPercentage(
  value,
  withSigns = true,
  fallback = 'N/A',
) {
  if (isNil(value) || value.length === 0) return fallback;

  return decoratePercentage(parseFloat(value) * 100, withSigns);
}

export function listToSentence(attributeList) {
  return new Intl.ListFormat('en-US', {
    style: 'long',
    type: 'conjunction',
  }).format(attributeList);
}

export function formatEntityNameWithType(value, entities = {}) {
  let entity = entities?.data?.find(
    ({ id }) => id.toString() === value.toString(),
  );

  if (isNil(entity) || isNil(value)) {
    return 'N/A';
  }

  return new EntityPresenter(entity).nameWithType;
}

export function isValidDateFormat(dateString) {
  var pattern = /^\d{2}\/\d{2}\/\d{2}$/;
  return pattern.test(dateString);
}
