import { isEmpty } from 'lodash';

import { BasePresenter } from '@ftf/lib/BasePresenter';

import { ENTITY_STATUSES } from '../config/constants';

export class EntityPresenter extends BasePresenter {
  static present(entity) {
    return new EntityPresenter(entity);
  }

  get isBusiness() {
    switch (this.entityType) {
      case 'Company':
      case 'Trust':
      case 'Issuer':
      case 'ClearingEntity':
      case 'InvestmentsClearingEntity':
        return true;

      default:
        return false;
    }
  }

  get hasAccounts() {
    const accounts = (this.bankTransferMethods || []).filter(
      btm => btm.accountType !== 'wallet',
    );

    return accounts.length > 0;
  }

  get hasWallets() {
    const accounts = (this.bankTransferMethods || []).filter(
      btm => btm.accountType === 'wallet',
    );

    return accounts.length > 0;
  }

  get nameWithType() {
    const entityType = this.entityType ? `(${this.entityType})` : '';

    return this.name ? `${this.name} ${entityType}` : '';
  }

  get isArchived() {
    return Boolean(this.archived);
  }

  get hasAddress() {
    return this.address && !isEmpty(this.address);
  }

  get streetAddress() {
    return this.hasAddress
      ? (this.address.street1 || '') + (this.address.street2 || '')
      : '';
  }

  get cityAddress() {
    return this.hasAddress
      ? `${this.address.city}, ${this.address.state} ${this.address.zip}`
      : '';
  }

  get isMakeItGoAway() {
    return Boolean(this.status === ENTITY_STATUSES.MAKE_IT_GO_AWAY);
  }

  get isSendAndReceive() {
    return Boolean(this.status === ENTITY_STATUSES.SEND_AND_RECEIVE);
  }

  get isUnverified() {
    return Boolean(this.status === ENTITY_STATUSES.UNVERIFIED);
  }
}
