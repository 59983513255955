export const BTM_STATUSES = {
  AUTOMATICALLY_VERIFIED: 'automatically_verified',
  CREDIT: 'credit',
  CREDIT_AND_DEBIT: 'credit_and_debit',
  DELETING: 'deleting',
  INACTIVE: 'inactive',
  MANUALLY_VERIFIED: 'manually_verified',
  NODE_DELETED: 'node_deleted',
  PENDING_AUTOMATIC_VERIFICATION: 'pending_automatic_verification',
  PENDING_MANUAL_VERIFICATION: 'pending_manual_verification',
};

export const ENTITY_STATUSES = {
  MAKE_IT_GO_AWAY: 'make_it_go_away',
  SEND_AND_RECEIVE: 'send_and_receive',
  UNVERIFIED: 'unverified',
};
